import React from 'react';
import SwipeUpSection from '../components/Fade';

const Footer = () => (
  <SwipeUpSection>
  <footer className="footer">
    <p>© 2024 Stemcorp Dynamics. All rights reserved.</p>
    <p className="contact-details">
      <span>Contact us: </span>
      <span className="contact-item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="icon-con"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
          />
        </svg>{" "}
        <a href="mailto:stemcorpdynamics@gmail.com">
          stemcorpdynamics@gmail.com
        </a>
      </span>
    </p>
   
  </footer>
  </SwipeUpSection>
);

export default Footer;
import React, { useState } from 'react';
import { FaGraduationCap, FaChalkboardTeacher, FaSchool, FaBook } from 'react-icons/fa';
import '../App.css'; // Import custom styles
import SwipeUpSection from '../components/Fade'; // Import the SwipeUpSection component

const iconStyle = { color: 'white', marginBottom: '10px' };

const Home = () => {
  const [isReadMore, setIsReadMore] = useState(false);
  const toggleReadMore = () => setIsReadMore(!isReadMore);

  return (
    <div>
      <SwipeUpSection>
        <section className="school-info">
          <div className="school-info-content">
            <div className="school-info-text">
              <h1>WELCOME TO STEMCORP DYNAMICS</h1>
              <h2>Since ~ 2020</h2>
              <h3>Innovation for the Youth By the Youth</h3>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                Stemcorp Dynamics stands at the forefront of technology education, catering to students of all ages with our innovative programs. We are dedicated to arming students with the skills essential for tomorrow's workforce through a comprehensive curriculum spanning robotics, 3D printing, drones, AI, and machine learning.
                {isReadMore && (
                  <>
                    <br />
                    <br />
                    Our seasoned faculty members, boasting robust engineering backgrounds and rich R&D experience, ensure top-tier instruction and mentorship. We furnish all necessary hardware and software for lab setups and provide student kits, facilitating immersive, hands-on learning experiences. We actively encourage student engagement in national and international events, fostering confidence and skill development on a global stage.
                    <br />
                    <br />
                    Embracing interdisciplinary collaboration, Stemcorp Dynamics champions teamwork and cooperation among students, nurturing a supportive community and honing invaluable soft skills. Our ultimate aim is to ready students for rewarding careers in STEM fields while igniting their passion for exploration and discovery.
                    <br />
                    <br />
                    Through strategic partnerships with industry pioneers, we grant students access to cutting-edge technology and lucrative career pathways. Regular workshops and seminars keep both students and faculty abreast of the latest industry advancements.
                    <br />
                    <br />
                    Central to our ethos is the integration of ethics and social responsibility into our curriculum, cultivating well-rounded individuals poised to leverage their knowledge for the greater good.
                    <br />
                    <br />
                    Embark on your journey to technological mastery with Stemcorp Dynamics and unlock your full potential in the ever-evolving landscape of technology.
                  </>
                )}
              </p>
              <button className="read-more-btn" onClick={toggleReadMore}>
                {isReadMore ? 'Read Less' : 'Read More'}
              </button>
            </div>
            <div className="school-info-image"></div>
          </div>
        </section>
      </SwipeUpSection>

      <SwipeUpSection>
        <div className="cards">
          <div className="card">
            <div className="card-inner">
              <div className="card-front">
                <FaGraduationCap size={50} style={iconStyle} />
                <h3>Experienced Trainers</h3>
              </div>
              <div className="card-back">
                <p>Extensive STEM knowledge and practical expertise, ensuring participants gain valuable, future-ready skills.</p>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-inner">
              <div className="card-front">
                <FaChalkboardTeacher size={50} style={iconStyle} />
                <h3>Interactive Workshops</h3>
              </div>
              <div className="card-back">
                <p>Our workshops engage participants with hands-on activities, fostering active learning and practical skill development.</p>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-inner">
              <div className="card-front">
                <FaSchool size={50} style={iconStyle} />
                <h3>Innovative Services</h3>
              </div>
              <div className="card-back">
                <p>Our facilities are equipped with the latest technology and resources, providing a conducive learning environment.</p>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-inner">
              <div className="card-front">
                <FaBook size={50} style={iconStyle} />
                <h3>24X7 Assistance</h3>
              </div>
              <div className="card-back">
                <p>Our curriculum is designed to provide a well-rounded education, covering all aspects of STEM.</p>
              </div>
            </div>
          </div>
        </div>
      </SwipeUpSection>

      <SwipeUpSection>
        <section className='photo-info'>
          <div className="photo-info-image"></div>
        </section>
      </SwipeUpSection>

      <SwipeUpSection>
        <section className="aim-section">
          <h1 style={{ color: 'black' }}>Our Clients</h1>
          <div className="cards">
            <div className="cardc card-sarala">
              <div className="cardc-inner">
                <div className="cardc-front-sbgj"></div>
                <div className="cardc-back">
                  <FaGraduationCap size={50} style={iconStyle} />
                  <h3>SARALA BIRLA GYAN JYOTI</h3>
                  <p style={{ color: 'white' }}>Amingaon North Guwahati</p>
                </div>
              </div>
            </div>

            <div className="cardc card-army">
              <div className="cardc-inner">
                <div className="cardc-front-aps"></div>
                <div className="cardc-back">
                  <FaGraduationCap size={50} style={iconStyle} />
                  <h3>THE ASSAM VALLEY SCHOOL</h3>
                  <p style={{ color: 'white' }}>Balipara, Tezpur</p>
                </div>
              </div>
            </div>

            <div className="cardc card-dps">
              <div className="cardc-inner">
                <div className="cardc-front-dps"></div>
                <div className="cardc-back">
                  <FaGraduationCap size={50} style={iconStyle} />
                  <h3>Delhi Public School</h3>
                  <p style={{ color: 'white' }}>Jorhat</p>
                </div>
              </div>
            </div>

            <div className="cardc card-claret">
              <div className="cardc-inner">
                <div className="cardc-front-claret"></div>
                <div className="cardc-back">
                  <FaGraduationCap size={50} style={iconStyle} />
                  <h3>ST. Claret Higher Secondary School</h3>
                  <p style={{ color: 'white' }}>Nongmengsong, Shillong</p>
                </div>
              </div>
            </div>
          </div>

          <div className="cards">
            <div className="cardc card-shem">
              <div className="cardc-inner">
                <div className="cardc-front-shem"></div>
                <div className="cardc-back">
                  <FaGraduationCap size={50} style={iconStyle} />
                  <h3>SHEMFORD GROUP <br />of<br /> FUTURISTIC SCHOOLS</h3>
                  <p style={{ color: 'white' }}>Choladhara Chariali, Jorhat</p>
                </div>
              </div>
            </div>

            <div className="cardc card-gps">
              <div className="cardc-inner">
                <div className="cardc-front-gps"></div>
                <div className="cardc-back">
                  <FaGraduationCap size={50} style={iconStyle} />
                  <h3>GUWAHATI PUBLIC SCHOOL</h3>
                  <p style={{ color: 'white' }}>Panjabari</p>
                </div>
              </div>
            </div>

            <div className="cardc card-apsn">
              <div className="cardc-inner">
                <div className="cardc-front-apsn"></div>
                <div className="cardc-back">
                  <FaGraduationCap size={50} style={iconStyle} />
                  <h3>ARMY PUBLIC SCHOOL</h3>
                  <p style={{ color: 'white' }}>Narangi, Guwahati</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </SwipeUpSection>
    </div>
  );
};

export default Home;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedinIn, FaYoutube, FaBars } from 'react-icons/fa'; // Add FaBars for hamburger icon
import logo from '../assets/images/STEM_LOGO.png'; // Replace with your logo image path

import '../App.css'; // Import your custom styles

const Header = ({ theme }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className={`header ${theme}-theme`}>
      <div className="logo-container">
        <img src={logo} alt="Stemcorp Logo" className="logo-img" />
        
      </div>
      <nav className={`nav ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/" onClick={closeMenu}>Home</Link></li>
          <li><Link to="/aboutus" onClick={closeMenu}>About Us</Link></li>
          <li><Link to="/contact" onClick={closeMenu}>Contact</Link></li>
          <li><Link to="/stem" onClick={closeMenu}>Stemcorp Robotics Kit</Link></li>
          <li><Link to="/service" onClick={closeMenu}>Service</Link></li>
        </ul>
      </nav>
      <div className="social-icons">
  <a href="https://www.facebook.com/profile.php?id=61551696189151" target="_blank" rel="noopener noreferrer" className="social-icon facebook-icon">
    <FaFacebook className="icon heartbeat" />
  </a>
  <a href="https://www.linkedin.com/company/stemcorp-dynamics/" target="_blank" rel="noopener noreferrer" className="social-icon linkedin-icon">
    <FaLinkedinIn className="icon heartbeat" />
  </a>
  <a href="https://www.instagram.com/stemcorpdynamics?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer" className="social-icon instagram-icon">
    <FaInstagram className="icon heartbeat" />
  </a>
  <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="social-icon youtube-icon">
    <FaYoutube className="icon heartbeat" />
  </a>
</div>
      <div className="hamburger" onClick={toggleMenu}>
        <FaBars />
      </div>
    </header>
  );
};

export default Header;

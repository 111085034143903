import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';

import About from './pages/Aboutus';
import Stem from './pages/Stem';
import Contact from './pages/Contact';
import Service from './pages/Service';
import ScrollToTop from './components/Scroll';

import './App.css';  // Import the CSS file

const App = () => (
  <Router>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      
      <Route path="/aboutus" element={<About />} />
      <Route path="/stem" element={<Stem />} />
     
      <Route path="/contact" element={<Contact />} />
      <Route path="/service" element={<Service />} />
    </Routes>
    <Footer />
    <ScrollToTop />
  </Router>
);

export default App;

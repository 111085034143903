import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import '../App.css'; // Import custom styles
import SwipeUpSection from '../components/Fade';
const Stem = () => (

  <div className="wel">
    <h1>ULTIMATE ROBOTICS KIT</h1>
    <p><i> ~ By Stemcorp Dynamics</i></p>
    <SwipeUpSection>
    <section className="kit-info">
      <div className="kit-info-content">
        <div className="kit-info-text">
          <h1 style={{ color: 'black' }}>ULTIMATE ROBOTICS KIT</h1>
          <p style={{ color: 'black', textAlign: 'justify' }}>
            Stemcorp Dynamics brings to you a custom robotics kit that can facilitate your child to step into the world of Robotics. <br></br> <br></br>
            The kit has 32 different items of which few are displayed here. To know more <b>CLICK ON <i>Download PDF </i>Button !!</b>
          </p>
        </div>
        <div className="kit-info-image"></div>
        <div className="cardkit">
          <div className="cardkit-inner">
            <div className="cardkit-front">
            </div>
            <div className="cardkit-back">
            </div>
          </div>
        </div>
      </div>
    </section>
    </SwipeUpSection>

    <SwipeUpSection>
    <div className="cards">
      <div className="cardk">
        <div className="cardk-inner">
          <div className="cardk-front">
            <h2><i>ARDUINO UNO</i></h2>
          </div>
          <div className="cardk-back">
          </div>
        </div>
      </div>

      <div className="cardm">
        <div className="cardm-inner">
          <div className="cardm-front">
            <h2><i>L293D MOTOR DRIVER SHIELD</i></h2>
          </div>
          <div className="cardm-back">
          </div>
        </div>
      </div>

      <div className="cardj">
        <div className="cardj-inner">
          <div className="cardj-front">
            <h2><i>JOYSTICK MODULE</i></h2>
          </div>
          <div className="cardj-back">
          </div>
        </div>
      </div>

      <div className="cardd">
        <div className="cardd-inner">
          <div className="cardd-front">
            <h2><i>1602 I2C DISPLAY </i></h2>
          </div>
          <div className="cardd-back">
          </div>
        </div>
      </div>
    </div>
    </SwipeUpSection>
    <SwipeUpSection>
    <a 
      href="https://drive.google.com/file/d/1YziuO-8qbAVNshBGgjqGpyQX3nqWD_on/view" 
      target="_blank" 
      rel="noopener noreferrer"
      className="download-pdf-button"
    >
      Download PDF
    </a>
    
    </SwipeUpSection>
  </div>
);

export default Stem;

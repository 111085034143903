import React from 'react';
import { useInView } from 'react-intersection-observer';
import '../App.css'; // Ensure this path is correct

const SwipeUpSection = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger every time the section is in view
    threshold: 0.1,     // Trigger when 10% of the section is visible
  });

  return (
    <div
      ref={ref}
      className={`swipe-up-section ${inView ? 'slide-up' : ''}`}
    >
      {children}
    </div>
  );
};

export default SwipeUpSection;

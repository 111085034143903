import React from 'react';
import '../App.css'; // Import custom styles
import img11 from '../assets/images/LAKHYA.png'; // Example import of an image
import img12 from '../assets/images/gaurav.png'; // Example import of an image
import img13 from '../assets/images/arup.jpg'; // Example import of an image
import img14 from '../assets/images/arun.jpg'; // Example import of an image
import img15 from '../assets/images/kaustav1.jpg';
import img16 from '../assets/images/parag.jpg';
import img17 from '../assets/images/hasan.jpg';
import img18 from '../assets/images/kim.jpg';
import img19 from '../assets/images/sud.jpg';
import img20 from '../assets/images/sudh.jpg';
import img21 from '../assets/images/saman.png';
import SwipeUpSection from '../components/Fade';
const About = () => {
  // Sample data for authorities and teachers
  const authorities = [
    { id: 1, name: 'Lakhyajit Hazarika', image: img11 },
    { id: 2, name: 'Gaurav Sharma', image: img12 },
  ];

  const teachers = [
    { id: 3, name: 'Arup Gogoi', image: img13 },
    { id: 4, name: 'Arun Gautam',  image: img14 },
    { id: 5, name: 'Kaustav P. Hazarika', image: img15 },
    { id: 6, name: 'Parag Talukdar',image: img16 },
    { id: 7, name: 'Hasan Shah',  image: img17 },
    { id: 8, name: 'Kynphamlang Shadap',  image: img18 },
    { id: 9, name: 'Rajesh Saha',  image: img19 },
    { id: 10, name: 'Sudhansu Protim Saikia',  image: img20  },
    { id: 10, name: 'Samannay Borah',  image: img21  },
    
  ];

  // Function to render academic cards
  const renderAcademicCards = (items) => (
    items.map(item => (
      <SwipeUpSection>
      <div key={item.id} className="academic-card">
        <div className="profile-pic">
          {item.image && <img src={item.image} alt={item.name} />}
        </div>
        <div className="info">
          <h2>{item.name}</h2>
          
        </div>
      </div>
      </SwipeUpSection>
    ))
  );

  return (
    
    <div className="academics-container">
      <SwipeUpSection>
      <div className="section">
        <h2>Meet The Founders</h2>
        <div className="cards-container">
          {renderAcademicCards(authorities)}
        </div>
      </div>
      </SwipeUpSection>
      

      <SwipeUpSection>
      <div className="section">
        <h2>Trainers & Engineers</h2>
        <div className="cards-container">
          {renderAcademicCards(teachers)}
        </div>
      </div>
      </SwipeUpSection>

      <SwipeUpSection>
      <div className="section">
        <h2>Recognitions</h2>
        <div className="cards">
          <div className="carda card-sta">
            <div className="carda-inner">
              <div className="carda-front-sta"></div>
              <div className="carda-back">
                <h3>STARTUP ASSAM</h3>
                <p style={{color: 'grey'}}>Initiative of Gov. of Assam</p>
              </div>
            </div>
          </div>
          <div className="carda card-sti">
            <div className="carda-inner">
              <div className="carda-front-sti"></div>
              <div className="carda-back">
                <h3>STARTUP INDIA</h3>
                <p style={{color: 'grey'}}>Initiative of Gov. of India</p>
              </div>
            </div>
          </div>
          <div className="carda card-abc">
            <div className="carda-inner">
              <div className="carda-front-abc"></div>
              <div className="carda-back">
                <h3>UDYAM</h3>
                <p style={{color: 'grey'}}>Ministry of Micro, Small & Medium Enterprise</p>
              </div>
            </div>
          </div>
          <div className="carda card-meity">
            <div className="carda-inner">
              <div className="carda-front-meity"></div>
              <div className="carda-back">
                <h3>MEITY TIDE 2.0</h3>
                <p style={{color: 'grey'}}>Incubation of Entrepreneurs</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </SwipeUpSection>
    </div>
    
  );
};

export default About;

import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import SwipeUpSection from '../components/Fade';
import '../App.css'; // Import custom styles

const Service = () => (
  <SwipeUpSection>
    <div className="wel">
      <h1>SERVICES OFFERED</h1>
      <p><i> ~ By Stemcorp Dynamics</i></p>

      <section className="service-info">
        <div className="service-info-content">
          <div className="service-info-column">
            <div className="cardser1">
              <h3>ATAL Tinkering Lab Setup.</h3>
            </div>
            <div className="cardser2">
              <h3>Robotics & AI Training.</h3>
            </div>
            <div className="cardser3">
              <h3>3D Printing Training.</h3>
            </div>
          </div>

          <div className="service-info-image">
            {/* Rotating image component */}
          </div>

          <div className="service-info-column">
            <div className="cardser4">
              <h3>IoT Training.</h3>
            </div>
            <div className="cardser5">
              <h3>Drones.</h3>
            </div>
            <div className="cardser6">
              <h3>IT Services.</h3>
            </div>
          </div>
        </div>
      </section>
    </div>
  </SwipeUpSection>
);

export default Service;
